﻿.DayPicker_portal__horizontal {
  z-index: 9;

  table td, table th {
    vertical-align: middle;
  }

  table td:not([align]), table th:not([align]) {
    text-align: center;
  }
}
